import React, { useEffect, useState } from "react";
import { getImageUrl } from "src/utils/common";
import { Link } from "react-router-dom";
import { setAuthState } from "src/redux/slices/AuthSlice";
import { useDispatch } from "react-redux";
import AuthService from "src/services/auth.service";
import UserService from "src/services/user.service";

const plannerMenuItems = [
  {
    title: "Updater",
    path: "/planner/updater/overview",
    icon: "/images/sidebar/check.png",
  },
  {
    title: "Stakeholders",
    path: "/planner/stakeholders",
    icon: "/images/sidebar/tools.png",
  },
];

const stakeholderMenuItems = [
  {
    title: "Updates",
    path: "/stakeholder/updates",
    icon: "/images/sidebar/check.png",
  },
];

const SideBar = () => {
  const dispatch = useDispatch();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let user = UserService.getCurrentUser();
    if (user.role.name === "Planner") {
      setMenuItems(plannerMenuItems);
    } else {
      setMenuItems(stakeholderMenuItems);
    }
  }, []);

  const menuClicked = (index) => {
    setSelectedMenuIndex(index);
  };
  return (
    <div className="min-w-[190px] h-full bg-[#FAFAFD] flex items-center justify-between flex-col px-5 pt-5 shadow-md">
      <div className="w-full">
        {menuItems.map((item, index) => (
          <Link
            onClick={() => menuClicked(index)}
            to={item.path}
            className={`w-full h-[32px] rounded-[4px] flex items-center px-3 gap-1 mb-2 ${
              selectedMenuIndex === index ? "bg-gradient-to-r from-[#0170CE] to-[#64B7FE]" : ""
            }`}
            key={index}
          >
            {/* <img src={getImageUrl(item.icon)} className="w-[20px] h-[16px] object-contain" /> */}
            <p
              className={`text-topbarTitleColor text-[16px] font-OpenSansMedium pl-2 ${
                selectedMenuIndex === index ? "text-white" : "text-topbarTitleColor"
              }`}
            >
              {item.title}
            </p>
          </Link>
        ))}
      </div>

      <p
        onClick={() => {
          dispatch(setAuthState(false));
          AuthService.logout();
        }}
        className="w-full h-[32px] rounded-[4px] flex items-center px-7 mb-2 text-[12px] cursor-pointer font-ChivoMonoBold"
      >
        Log Out
      </p>
    </div>
  );
};

export default SideBar;
