import { words } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

const CodeDropDown = ({ options, onSelect, placeholder = "Select code" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleTypeClick = (item, index) => {
    setIsOpen(false);
    setSelectedItem(options[index]);
    onSelect(item);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className="relative w-full">
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="w-full flex items-center h-[30px] justify-between px-2 py-2 border rounded-[6px] text-gray-800 focus:outline-none focus:border-blue-500 cursor-pointer"
        >
          <span className="font-OpenSansRegular text-[400] text-[12px] text-titleColor">
            {selectedItem ? selectedItem : placeholder}
          </span>
          <FiChevronDown className="ml-2" size={15} />
        </div>
        {isOpen && (
          <ul className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg max-h-[200px] overflow-y-auto">
            {options &&
              options.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleTypeClick(item, index)}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-200 font-OpenSansRegular text-[12px]"
                >
                  {item}
                </li>
              ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default CodeDropDown;
