import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import BlueButton from "src/components/buttons/BlueButton";
import WhiteButton from "src/components/buttons/WhiteButton";
import CodeDropDown from "src/components/dropdown/CodeDropDown";
import EmailInput from "src/components/inputs/EmailInput";
import CodeService from "src/services/code.service";
import InviteService from "src/services/invite.service";
import UserService from "src/services/user.service";
import { toast } from "react-toastify";

const AddModal = ({ shown, onCancel, codeArray }) => {
  const [code, setCode] = useState(null);
  const [email, setEmail] = useState(null);
  const user = UserService.getCurrentUser();

  const save = async () => {
    if (code && email) {
      try {
        let res = await InviteService.sendInvite(user.id, email, code, user.project.id);
        if (res.status === 201) {
          toast("Invite sent", { type: "success" });
          onCancel();
        }
      } catch (e) {
        console.log("🚀 ~ save ~ error:", e);
      }
    }
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-100">
        <OutsideClickHandler onOutsideClick={onCancel}>
          <div className="w-[479px]  bg-white rounded-[6px]">
            <div className="w-full h-[48px] bg-[#e5e7f4] flex items-center justify-center">
              <span className="font-ChivoMonoMedium text-[20px]">Invite Stakeholder</span>
            </div>
            <div className="w-full px-[8px] py-[12px] bg-[#FAFAFD]">
              <p className="font-ChivoMonoMedium text-[12px] text-[#333333] pb-2">Email</p>
              <EmailInput onEmailChanged={(value) => setEmail(value)} />

              <p className="font-ChivoMonoMedium text-[12px] text-[#333333] pb-2 mt-5">ACTIVITY CODE</p>
              <CodeDropDown
                options={codeArray}
                onSelect={(index) => {
                  setCode(codeArray[index]);
                }}
              />

              <div className="w-full h-[32px] flex items-center justify-between gap-[12px] pt-3 mt-7">
                <WhiteButton title="CANCEL" onClick={onCancel} />
                <BlueButton title="SEND" onClick={save} />
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default AddModal;
