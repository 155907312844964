import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";

const TaskDropDown = ({ selectedTask, onSelect, tasks }) => {
  const [open, setOpen] = useState(false);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpen(false);
      }}
    >
      <div className="relative w-full">
        <button
          onClick={() => {
            setOpen(true);
          }}
          className="w-full flex items-center h-[30px] justify-between px-2 py-2 border rounded-[6px] text-gray-800 focus:outline-none"
        >
          <span className="font-OpenSansRegular text-[400] text-[12px] text-titleColor">
            {selectedTask ? selectedTask.name : "Select Task"}
          </span>
          <FiChevronDown className="ml-2" size={15} />
        </button>
        {open && (
          <ul className="absolute z-10 mt-[1px] w-full bg-white border rounded-md shadow-lg max-h-[300px] overflow-y-auto">
            {tasks.map((task, index) => (
              <li
                key={index}
                onClick={() => {
                  setOpen(false);
                  onSelect(task);
                }}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200 font-OpenSansRegular text-[12px]"
              >
                {task.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default TaskDropDown;
