import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AiFillMessage } from "react-icons/ai";
import { useSelector } from "react-redux";

import BlueButton from "src/components/buttons/BlueButton";
import LeftArrowButton from "src/components/buttons/LeftArrowButton";
import ProgressBar from "src/components/ProgressBar";
import RightArrowButton from "src/components/buttons/RightArrowButton";
import TableFooter from "src/components/TableFooter";
import CriticalPath from "src/components/status/CriticalPath";
import Delayed from "src/components/status/Delayed";
import OnTime from "src/components/status/OnTime";
import AcceptButton from "src/components/buttons/AcceptButton";
import RejectButton from "src/components/buttons/RejectButton";
import AcceptAllButton from "src/components/buttons/AcceptAllBtton";
import RejectModal from "./components/RejectModal";
import InviteService from "src/services/invite.service";
import { formatDate } from "src/utils/common";
import Pending from "src/components/status/Pending";
import Accepted from "src/components/status/Accepted";
import Rejected from "src/components/status/Rejected";
import UpdateService from "src/services/update.service";
import { toast } from "react-toastify";
import UserService from "src/services/user.service";
import WarningButton from "src/components/buttons/WarningButton";
import MessageModal from "./components/MessageModal";
import MessageService from "src/services/message.service";
import ActivityLogs from "src/components/ActivityLogs";

const UpdateDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = UserService.getCurrentUser();

  // const invites = useSelector((state) => state.Tasks.invites);
  const [invite, setInvite] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [rejectModalShown, setRejectModalShown] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  //Message Modal
  const [messageModalShown, setMessageModalShown] = useState(false);
  const [messageTask, setMessageTask] = useState(null);
  //Rejecting the task
  const [rejectTask, setRejectTask] = useState(null);

  //activity logs
  const [activityLogs, setActivityLogs] = useState([]);
  const [activityLogModalShown, setActivityLogModalShown] = useState(false);
  // const [updateForLog, setUpdateForLog] = useState(null);
  const [taskForLog, setTaskForLog] = useState(null);

  useEffect(() => {
    getAllUpdates();
  }, []);

  const getAllUpdates = async () => {
    InviteService.getOne(user.project.id, id)
      .then((response) => {
        setTasks(response.data.tasks);
        // let pendingUpdates = [];
        // let otherUpdates = [];

        // for (let task of response.data.tasks) {
        //   for (let i = 0; i < task.updates.length; i++) {
        //     task.updates[i]["name"] = task.name;
        //     task.updates[i]["startDate"] = task.startDate;
        //     task.updates[i]["endDate"] = task.endDate;
        //     if (task.updates[i].status == 0) {
        //       pendingUpdates.push(task.updates[i]);
        //     } else {
        //       otherUpdates.push(task.updates[i]);
        //     }
        //   }
        // }
        // pendingUpdates.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        // otherUpdates.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        // setUpdates(pendingUpdates.concat(otherUpdates));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const accept = (index) => {
    setLoadingIndex(index);
    let update = tasks[index].updates[0];
    UpdateService.accept({ userId: user.id, update: update })
      .then((response) => {
        getAllUpdates();
        toast("Update accepted");
        setLoadingIndex(null);
      })
      .catch((error) => {
        toast("An error occured");
        setLoadingIndex(null);
      });
  };

  const reject = (task, message) => {
    let update = task.updates[0];

    UpdateService.reject({
      update: update,
      userId: user.id,
      message: message,
      task: task,
    })
      .then((response) => {
        getAllUpdates();
        toast("Update rejected");
        setRejectModalShown(false);
      })
      .catch((error) => {
        toast("An error occured");
        setRejectModalShown(false);
      });
  };

  const getActivityLogs = async (taskId) => {
    try {
      let response = await UpdateService.getActivityLogsForTask(taskId);
      setActivityLogs(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async (message) => {
    let data = { message, sender: user.id, task: taskForLog.id };
    try {
      await MessageService.create(data);
      getActivityLogs(taskForLog.id);
    } catch (error) {
      console.log("Sending message error: ", error);
    }
  };

  return (
    <div className="w-full h-full px-[24px] py-[16px] bg-[#F5F5FB] flex flex-col">
      <RejectModal
        send={reject}
        shown={rejectModalShown}
        onCancel={() => setRejectModalShown(false)}
        task={rejectTask}
      />
      <MessageModal shown={messageModalShown} task={messageTask} onCancel={() => setMessageModalShown(false)} />
      <ActivityLogs
        send={sendMessage}
        logs={activityLogs}
        // update={updateForLog}
        shown={activityLogModalShown}
        onCancel={() => setActivityLogModalShown(false)}
      />

      <div className="w-full mt-5 bg-[#fafafd] shadow-lg p-[16px] flex-auto">
        {/* <div className="w-full flex items-center justify-between">
          <div></div>
          <div className="flex items-center justify-between gap-3">
            <LeftArrowButton />
            <span className="font-ChivoMonoMedium text-[16px] text-[#333333]">{`${updateDetail.package} / ${updateDetail.stakeholder}`}</span>
            <RightArrowButton />
          </div>
        </div>
        <p className="font-OpenSansRegular text-[16px] font-black my-5">SUMMARY</p>
        <div className="w-full h-[120px] flex gap-[16px]">
          <div className="flex items-center justify-center w-full h-full bg-white border-[#ced0dc] border-[2px] rounded-md shadow-lg">
            <div className="flex flex-col items-center">
              <span className="font-ChivoMonoMedium text-[48px] text-[#333333]">
                8<span className="font-ChivoMonoMedium text-[32px] text-[#333333]">/48</span>
              </span>
              <p className="font-OpenSansMedium text-[12px] font-black mt-[-8px]">STARTED TASKS</p>
            </div>
          </div>

          <div className="flex items-center justify-center w-full h-full bg-white border-[#ced0dc] border-[2px] rounded-md shadow-lg">
            <div className="flex flex-col items-center">
              <span className="font-ChivoMonoMedium text-[32px] text-[#333333]">16%</span>
              <div className="h-[16px] w-[200px]">
                <ProgressBar progress={20} />
              </div>
              <p className="font-OpenSansMedium text-[12px] font-black mt-[5px]">OVERALL PROGRESS</p>
            </div>
          </div>

          <div className="flex items-center justify-center w-full h-full bg-white border-[#ced0dc] border-[2px] rounded-md shadow-lg">
            <div className="flex flex-col items-center">
              <span className="font-ChivoMonoMedium text-[48px] text-[#f00]">1</span>
              <p className="font-OpenSansMedium text-[12px] font-black mt-[-8px]">IMPACT ON CRITICAL PATH</p>
            </div>
          </div>
        </div> */}
        <p
          className="font-OpenSansRegular text-[16px] text-blue-400 underline my-5 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          BACK
        </p>
        <p className="font-OpenSansRegular text-[16px] font-black my-5">ACTIVITIES</p>
        <div className="bg-white shadow-md rounded-[4px] py-2">
          {/* <div className="w-full flex items-center justify-end pr-[32px]">
            <AcceptAllButton />
          </div> */}
          <table className="w-full">
            <thead>
              <tr className=" border-b-[2px] border-borderColor ">
                <th className="w-[70px]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center px-2 h-[40px] text-[#606060]">
                    <span>#</span>
                  </div>
                </th>

                <th className="w-[20%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center px-2 h-[40px] text-[#606060]">
                    <span>TASK NAME</span>
                  </div>
                </th>

                <th className="w-[20%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>PLANNED DATE</span>
                  </div>
                </th>

                <th className="w-[10%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>ACTUAL</span>
                  </div>
                </th>

                {/* <th className="w-[10%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>CHANGED DATE</span>
                  </div>
                </th> */}

                <th className="w-[10%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>PROGRESS %</span>
                  </div>
                </th>

                {/* <th className="w-[20%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>IMPACT (DAYS) </span>
                  </div>
                </th> */}

                <th>
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>REVIEW STATUS</span>
                  </div>
                </th>

                <th className="w-[150px]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>ACTIONS </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tasks &&
                tasks.map((task, index) => (
                  <tr key={index} className="h-[80px] w-full">
                    <td className="px-2">
                      <span className="font-OpenSansRegular text-[14px] text-[#333333]">{index + 1}</span>
                    </td>
                    <td className="px-2">
                      <span className="font-OpenSansRegular text-[14px] text-[#333333]">{task.name}</span>
                    </td>
                    <td>
                      <div className="flex items-center justify-center">
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col gap-[12px]">
                            <span className="font-ChivoMonoRegular text-[12px] text-[#8d8d8d]">Start</span>
                            <span className="font-ChivoMonoRegular text-[12px] text-[#8d8d8d]">Finish</span>
                          </div>
                          <div className="flex flex-col gap-2 ">
                            <span className="font-ChivoMonoMedium text-[14px] text-[#333333]">
                              {formatDate(task.startDate)}
                              {task.updates.length > 0 && task.updates[0].updatedStartDate && (
                                <span className="font-ChivoMonoRegular text-[14px] text-[#333333]">
                                  &nbsp;→&nbsp;{formatDate(task.updates[0].updatedStartDate)}
                                </span>
                              )}
                            </span>
                            <div className="relative">
                              <span className="font-ChivoMonoMedium text-[14px] text-[#333333]">
                                {formatDate(task.endDate)}
                                {task.updates.length > 0 && task.updates[0].updatedEndDate && (
                                  <span className="font-ChivoMonoRegular text-[14px] text-[#333333]">
                                    &nbsp;→&nbsp;{formatDate(task.updates[0].updatedEndDate)}
                                  </span>
                                )}
                              </span>
                              <div className="absolute bottom-[0px] right-[-30px] bg-blue-400">
                                {task.updates.length > 0 && task.updates[0].delayCategory && (
                                  <WarningButton
                                    onClick={() => {
                                      setMessageModalShown(true);
                                      setMessageTask(task);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex flex-col gap-5">
                        <input type="checkbox" checked={task.startDateConfirmed} readOnly={true} />
                        <input type="checkbox" checked={task.endDateConfirmed} readOnly={true} />
                      </div>
                    </td>
                    {/* <td>
                      <div className="flex items-center justify-center">
                        {task.updates.length > 0 && task.updates[0].updatedStartDate && (
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col gap-[12px]">
                              <span className="font-ChivoMonoRegular text-[12px] text-[#8d8d8d]">Start</span>
                              <span className="font-ChivoMonoRegular text-[12px] text-[#8d8d8d]">Finish</span>
                            </div>

                            <div className="flex flex-col gap-2">
                              <span className="font-ChivoMonoRegular text-[14px] text-[#333333]">
                                {formatDate(task.updates[0].updatedStartDate)}
                              </span>
                              <span className="font-ChivoMonoRegular text-[14px] text-[#333333]">
                                {formatDate(task.updates[0].updatedEndDate)}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </td> */}

                    {/* <td>
                      <div className="w-full h-[60px] flex items-center justify-center">
                        <div className=" h-full flex flex-col ">
                          <div className="w-full h-[50%] flex items-center ">
                            {task.updates.length > 0 && task.updates[0].updatedStartDate && (
                              <>
                                <span className="font-OpenSansMedium text-[13px] text-[#8D8D8D] w-[50px]">Start</span>
                                <span className="font-OpenSansMedium text-[13px] text-[#333333]">
                                  {formatDate(task.updates[0].updatedStartDate)}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="w-full h-[50%] flex items-center ">
                            {task.updates.length > 0 && task.updates[0].updatedEndDate && (
                              <>
                                <span className="font-OpenSansMedium text-[13px] text-[#8D8D8D] w-[50px]">Finish</span>
                                <span className="font-OpenSansMedium text-[13px] text-[#333333]">
                                  {formatDate(task.updates[0].updatedEndDate)}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </td> */}

                    <td>
                      {task.updates.length > 0 && (
                        <div>
                          {task.updates[0].updatedProgress && task.updates[0].updatedProgress !== task.progress ? (
                            <div className="flex items-center justify-center">
                              <span className="font-OpenSansRegular text-[14px] text-[#333333]">
                                {`${task.progress} → ${task.updates[0].updatedProgress}`}
                              </span>
                            </div>
                          ) : (
                            <div className="flex items-center justify-center">
                              <span className="font-OpenSansRegular text-[14px] text-[#333333]">{task.progress}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </td>

                    <td>
                      <div className="flex items-center justify-center">
                        <div className="flex items-center relative">
                          <div>
                            {task.updates.length > 0 && (
                              <>
                                {task.updates[0].status === 0 ? (
                                  <Pending />
                                ) : task.updates[0].status === 1 ? (
                                  <Accepted />
                                ) : task.updates[0].status === 2 ? (
                                  <Rejected />
                                ) : null}
                              </>
                            )}
                          </div>
                          <div
                            onClick={() => {
                              setActivityLogModalShown(true);
                              // setUpdateForLog(task.updates[0]);
                              setTaskForLog(task);
                              getActivityLogs(task.id);
                            }}
                            className="cursor-pointer"
                          >
                            <AiFillMessage size={20} />
                          </div>
                        </div>
                      </div>

                      {/* <div className="flex items-center justify-center">
                        <div className="relative flex items-center">
                          {item.status === 0 ? (
                            <Pending />
                          ) : item.status === 1 ? (
                            <Accepted />
                          ) : item.status === 2 ? (
                            <Rejected />
                          ) : null}

                          {item.status === 2 && (
                            <div
                              onClick={() => {
                                setActivityLogModalShown(true);
                                setUpdateForLog(item.updates[0]);
                                setTaskIdForLog(item.taskId);
                                getActivityLogs(item.taskId);
                              }}
                              className="cursor-pointer absolute right-[-21px]"
                            >
                              <AiFillMessage size={20} />
                            </div>
                          )}
                        </div>
                      </div> */}
                    </td>

                    <td>
                      {task.updates.length > 0 && task.updates[0].status === 0 && (
                        <div className="flex items-center justify-center">
                          <div className="flex items-center gap-3">
                            <AcceptButton onClick={() => accept(index)} loading={loadingIndex === index} />
                            <RejectButton
                              onClick={() => {
                                setRejectTask(task);
                                setRejectModalShown(true);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <TableFooter pageNumber={1} totalNumber={100} /> */}
        </div>
      </div>
    </div>
  );
};

export default UpdateDetail;
