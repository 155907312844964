import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import BlueButton from "src/components/buttons/BlueButton";
import TableFooter from "src/components/TableFooter";
import InviteService from "src/services/invite.service";
import AddModal from "./components/AddModal";
import Pending from "./components/Pending";
import Accepted from "./components/Accepted";
import CodeService from "src/services/code.service";
import UserService from "src/services/user.service";

const Stakeholders = () => {
  const navigate = useNavigate();
  const [modalShown, setModalShown] = useState(false);
  const [codeArray, setCodeArray] = useState([]);
  const [invites, setInvites] = useState([]);
  const user = UserService.getCurrentUser();

  useEffect(() => {
    getAll();
    getCodes();
  }, []);

  const getAll = async () => {
    try {
      let response = await InviteService.getAll(user.project.id);
      setInvites(response.data);
    } catch (err) {
      console.log("Fetching invites error: ", err);
    }
  };

  const getCodes = async () => {
    try {
      let user = UserService.getCurrentUser();
      let res = await CodeService.getAll(user.id);
      setCodeArray(res.data);
    } catch (e) {
      console.log("🚀 ~ Fetching code error:", e);
    }
  };

  return (
    <div className="w-full h-full bg-[#F5F5FB] flex flex-col flex-auto ">
      <AddModal
        shown={modalShown}
        codeArray={codeArray}
        onCancel={() => {
          setModalShown(false);
          getAll();
        }}
      />
      <span className="font-OpenSansMedium text-[24px] font-black">Stakeholders</span>
      <div className="w-full mt-5 bg-[#fafafd] shadow-lg p-[16px] flex-auto">
        <div className="bg-white shadow-md rounded-[4px]">
          <div className="w-full h-[24px] flex itesms-center justify-between px-2 py-2 mb-2">
            <div></div>
            <div className="w-[100px] h-full">
              <BlueButton title="INVITE" onClick={() => setModalShown(true)} />
            </div>
          </div>
          <table className="w-full">
            <thead>
              <tr className=" border-b-[2px] border-borderColor ">
                <th className="w-[70px]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center px-2 h-[40px] text-[#606060]">
                    <span>#</span>
                  </div>
                </th>

                <th className="w-[30%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>STAKEHOLDER</span>
                  </div>
                </th>

                <th className="w-[30%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>PACKAGE</span>
                  </div>
                </th>

                <th className="w-[30%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>REVIEW STATUS</span>
                  </div>
                </th>

                {/* <th className="w-[100px]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>ACTIONS </span>
                  </div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {invites.map((invite, index) => (
                <tr key={index} className="h-[80px] w-full">
                  <td className="px-2">
                    <span className="font-OpenSansRegular text-[14px] text-[#333333]">{index + 1}</span>
                  </td>
                  <td>
                    <div className="mx-auto flex justify-center">
                      <span className="font-OpenSansRegular text-[14px] text-[#333333]">
                        {invite.invited && invite.invited.stakeholder ? invite.invited.stakeholder?.name : invite.email}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="mx-auto flex justify-center">
                      <span className="font-OpenSansRegular text-[14px] text-[#333333]">{invite.code}</span>
                    </div>
                  </td>
                  <td>
                    <div className="mx-auto flex justify-center">{invite.accepted ? <Accepted /> : <Pending />}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <TableFooter pageNumber={1} totalNumber={100} /> */}
        </div>
      </div>
    </div>
  );
};

export default Stakeholders;
