import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { IoMdSend } from "react-icons/io";

import BlueButton from "src/components/buttons/BlueButton";
import WhiteButton from "src/components/buttons/WhiteButton";
import TextInput from "./inputs/TextInput";
import { formatDate } from "src/utils/common";

const ActivityLogs = ({ logs, shown, onCancel, send }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage("");
  }, [shown]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && message.length > 0) {
      send(message);
      setMessage("");
    }
  };

  return (
    shown && (
      <div
        className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-100"
        style={{ zIndex: 1000 }}
      >
        <OutsideClickHandler onOutsideClick={onCancel}>
          <div className="w-[700px] max-h-[600px] bg-white flex flex-col ">
            <div className="w-full h-[48px] bg-[#e5e7f4] flex items-center justify-center relative">
              <span className="font-ChivoMonoMedium text-[15px]">ACTIVITY LOGS</span>
              <div onClick={onCancel} className="cursor-pointer absolute right-4">
                <IoClose size={20} />
              </div>
            </div>
            <div className="w-full px-4 bg-[#FAFAFD] flex-auto overflow-auto">
              <table className="w-full rounded-md">
                <thead>
                  <tr className=" border-b-[2px] border-borderColor ">
                    <th className="w-[20%]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                        <span>DATE</span>
                      </div>
                    </th>
                    <th className="w-[20%]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                        <span>PARTY</span>
                      </div>
                    </th>
                    <th className="w-[60%]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                        <span>ACTIVITY</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log, index) => (
                    <tr key={index} className="w-full h-[30px]">
                      <td>
                        <div className="w-full flex items-center justify-center">
                          <span className="font-OpenSansRegular text-[12px] text-[#333333]">
                            {formatDate(log.date)}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="w-full flex items-center justify-center">
                          <span className="font-OpenSansRegular text-[12px] text-[#333333]">{log.party}</span>
                        </div>
                      </td>
                      <td>
                        <div className="w-full flex items-center justify-center">
                          <span className="font-OpenSansRegular text-[12px] text-[#333333]">{log.activity}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-full px-4 py-2">
              <span className="font-ChivoMonoMedium text-[15px]">Send Comment</span>
              <div className="flex items-center gap-3">
                <div className="w-full h-[32px] border-[#CED0DC] border-[2px] rounded-[5px] flex items-center justify-center px-[15px] bg-white shadow-sm">
                  <input
                    value={message}
                    type="text"
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                    placeholder="Type here..."
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div
                  onClick={() => {
                    if (message.length > 0) {
                      setMessage("");
                      send(message);
                    }
                  }}
                  className="cursor-pointer"
                >
                  <IoMdSend size={25} />
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default ActivityLogs;
