import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TabBar from "./components/TabBar";

const UpdaterTopBar = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (location.pathname === "/planner/updater/overview") {
      setActiveTab(0);
    } else if (
      location.pathname === "/planner/updater/updates" ||
      location.pathname.includes("/planner/updater/updates")
    ) {
      setActiveTab(1);
    } else if (location.pathname === "/planner/updater/packages") {
      setActiveTab(2);
    }
  }, [location.pathname]);

  return (
    <div className="w-full bg-[#F5F5FB] flex flex-col">
      <p className="font-OpenSansMedium text-[24px] font-black mb-3">Project #1</p>
      <TabBar
        tabIndex={activeTab}
        tabClicked={(index) => {
          setActiveTab(index);
        }}
      />
    </div>
  );
};

export default UpdaterTopBar;
