import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { PiDatabase } from "react-icons/pi";
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const [doughnutData, setDoughnutData] = useState({
    labels: ["NOT STARTED", "IN PROGRESS", "COMPLETED"],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: ["rgb(255, 64, 105)", "rgb(5, 155, 255)", "rgb(64, 255, 105)"],
        hoverOffset: 4,
      },
    ],
  });

  useEffect(() => {
    setDoughnutData({
      ...doughnutData,
      labels: [`NOT STARTED(${data.notStarted})`, `IN PROGRESS(${data.inProgress})`, `COMPLETED(${data.completed})`],
      datasets: [
        {
          data: [data.notStarted, data.inProgress, data.completed],
          backgroundColor: ["rgb(255, 64, 105)", "rgb(5, 155, 255)", "rgb(64, 255, 105)"],
          hoverOffset: 4,
        },
      ],
    });
  }, [PiDatabase]);

  return (
    <div className="bg-white shadow-md rounded-sm p-3">
      <div className="flex items-center gap-1">
        <span className="font-OpenSansBold text-[31px] font-black ">
          {data.notStarted + data.inProgress + data.completed}&nbsp;
        </span>
        <span className="font-OpenSansMedium text-[16px] font-black mt-1 ">TOTAL ACTIVITIES</span>
      </div>
      <Doughnut data={doughnutData} />
    </div>
  );
};

export default PieChart;
