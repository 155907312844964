import { current } from "@reduxjs/toolkit";
import api from "./api";
import AuthService from "./auth.service";

const getAll = (projectId) => {
  return api.get("/api/invite/get-all", { params: { projectId } });
};

const getAcceptedAll = (projectId) => {
  return api.get("/api/invite/get-accepted-all", { params: { projectId } });
};

const getOne = (projectId, inviteId) => {
  return api.get("/api/invite/get-one", { params: { projectId, inviteId } });
};

const sendInvite = (invitedBy, email, code, project) => {
  return api.post("/api/auth/send-invite", { invitedBy, email, code, project });
};

const acceptInvite = (token, password) => {
  return api.get(`/api/auth/accept_invite`, { params: { token, password } });
};

const validateInviteToken = (token) => {
  return api.get(`/api/auth/validate_invite_token/${token}`);
};

const InviteService = {
  getAll,
  getAcceptedAll,
  getOne,
  sendInvite,
  acceptInvite,
  validateInviteToken,
};

export default InviteService;
