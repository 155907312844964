import React, { useState, useEffect } from "react";

function EmailInput({ onEmailChanged }) {
  const [email, setEmail] = useState("");

  useEffect(() => {}, []);

  const emailChanged = (e) => {
    onEmailChanged(e.target.value);
    setEmail(e.target.value);
  };

  return (
    <div className="w-full h-[32px] border-[#CED0DC] border-[2px] rounded-[5px] flex items-center justify-center px-[15px] bg-white shadow-sm">
      <input
        value={email}
        type="email"
        onChange={emailChanged}
        className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
        placeholder="Enter email"
      />
    </div>
  );
}

export default EmailInput;
