import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import BlueButton from "src/components/buttons/BlueButton";
import WhiteButton from "src/components/buttons/WhiteButton";

const RejectModal = ({ task, shown, onCancel, send }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage("");
  }, [shown]);

  return (
    shown && (
      <div
        className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-100"
        style={{ zIndex: 1000 }}
      >
        <OutsideClickHandler onOutsideClick={onCancel}>
          <div className="w-[658px]  bg-white ">
            <div className="w-full h-[48px] bg-[#e5e7f4] flex items-center justify-center">
              <span className="font-ChivoMonoMedium text-[15px]">{`Reject Update: ${task.name}`}</span>
            </div>
            <div className="w-full px-[8px] py-[12px] bg-[#FAFAFD]">
              <span className="font-ChivoMonoMedium text-[14px] text-[#333333] pb-2">EXPLANATION</span>
              <textarea
                className="w-full h-[93px] border-[2px] border-[#ced0dc] rounded-md font-ChivoMonoLight text-[12px] p-1 resize-none"
                placeholder="Type here"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="w-full h-[32px] flex items-center justify-between gap-[12px] pt-3">
                <WhiteButton title="CANCEL" onClick={onCancel} />
                <BlueButton title="SEND" onClick={() => send(task, message)} />
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default RejectModal;
