import React from "react";
import { getImageUrl } from "src/utils/common";

const TopBar = () => {
  return (
    <div className="w-full min-h-[48px] bg-[#E5E7F4] flex items-center justify-between px-[24px] shadow-md">
      <div className="flex items-center gap-[20px]">
        <img src={getImageUrl("/images/common/logo.png")} className="w-[34px] h-[34px]" />
        <span className="text-topbarTitleColor text-[28px] font-OpenSansRegular ">Instaplan</span>
      </div>
    </div>
  );
};

export default TopBar;
