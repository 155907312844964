const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
};

const updateLocalAccessToken = (token) => {
  localStorage.setItem("accessToken", token);
};

const getUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
};

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const removeAccessToken = () => {
  localStorage.removeItem("accessToken");
};

const saveSharepointToken = (token) => {
  localStorage.setItem("sharepointToken", token);
};

const getSharepointToken = (token) => {
  localStorage.setItem("sharepointToken", token);
};

const TokenService = {
  saveSharepointToken,
  getSharepointToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  removeAccessToken,
};

export default TokenService;
