import React, { useEffect, useState } from "react";
import moment from "moment";
import TaskService from "src/services/task.service";
import UserService from "src/services/user.service";
import PieChart from "./PieChart";
import PuffLoader from "react-spinners/PuffLoader";
import InviteService from "src/services/invite.service";
import UpdatesPanel from "./UpdatesPanel";
import StakeholderDropDown from "./components/StakeholderDropdown";
import UpdateService from "src/services/update.service";
import BarChart from "./BarChart";
import LogTable from "./LogTable";
import { formatDate } from "src/utils/common";
import TaskDropDown from "./components/TaskDropdown";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#007DE5",
};

const Overview = () => {
  const user = UserService.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [invites, setInvites] = useState([]);
  const [updatePanelData, setUpdatePanelData] = useState({});
  //stakeholder dropdown
  const [stakeholderArray, setStakeholderArray] = useState([]);
  const [selectedStakeholder, setSelectedStakeholder] = useState(null);
  //progress chart
  const [progressChartData, setProgressChartData] = useState({
    notStarted: 0,
    inProgress: 0,
    completed: 0,
  });
  //bar chart
  const [barData, setBarData] = useState({ labels: [], data: [] });
  //activity logs
  const [activityLogs, setActivityLogs] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    const runAsyncFunc = async () => {
      setLoading(true);
      await getAllStakeholders();
      getAllTasks();
    };
    runAsyncFunc();
  }, []);

  useEffect(() => {
    UpdateService.getAcceptedAll(user.project.id)
      .then((response) => {
        delayBarChat(response.data);
        getActivityLogs(response.data);
      })
      .catch((error) => {
        console.log("get accepted invite error: ", error);
      });
    setSelectedTask(null);
    if (selectedStakeholder) {
      TaskService.getAllTasksByStakeholder(selectedStakeholder.code, user.project.id)
        .then((response) => {
          setTasks(response.data);
        })
        .catch((error) => {
          console.log("get accepted invite error: ", error);
        });

      UpdateService.getActivityLogsForStakeholder(selectedStakeholder.code, user.project.id)
        .then((response) => {
          setActivityLogs(response.data);
          console.log("🚀 ~ useEffect ~ response:", response);
        })
        .catch((error) => {
          console.log("get accepted invite error: ", error);
        });
    }
  }, [selectedStakeholder]);

  // useEffect(() => {
  //   setSelectedTask(tasks[0]);
  // }, [tasks]);

  useEffect(() => {
    if (selectedTask) {
      setActivityLogs([]);
      UpdateService.getActivityLogsForTask(selectedTask.id)
        .then((response) => {
          setActivityLogs(response.data);
          console.log("🚀 ~ useEffect ~ response:", response);
        })
        .catch((error) => {
          console.log("get accepted invite error: ", error);
        });
    }
  }, [selectedTask]);

  const getActivityLogs = (updatesData) => {
    let updates = [...updatesData];
    if (selectedStakeholder.code !== "All") {
      updates = updatesData.filter((update) => update.code === selectedStakeholder.code);
    }

    let tempLogs = [];
    for (let update of updates) {
      let updateString = "";
      if (update.updatedStartDate) {
        updateString += `START DATE ${formatDate(update.startDate)} → ${formatDate(update.updatedStartDate)}`;
      }

      if (update.updatedEndDate) {
        if (updateString.length > 0) updateString += ", ";
        updateString += `END DATE ${formatDate(update.endDate)} → ${formatDate(update.updatedEndDate)}`;
        updateString += `, CATEGORY: ${update.delayCategory.name}, EXPLANATION: ${update.delayReason}`;
      }

      if (update.updatedProgress) {
        if (updateString.length > 0) updateString += ", ";
        updateString += `COMPLETION % ${update.progress} → ${update.updatedProgress}`;
      }

      let log = {
        date: formatDate(update.createdAt),
        stakeholder: stakeholderArray.find((stakeholder) => stakeholder.code === update.code).name,
        activity: update.name,
        update: updateString,
      };
      tempLogs.push(log);
    }
    tempLogs.sort((a, b) => new Date(b.date) - new Date(a.date));
    setActivityLogs(tempLogs);
  };

  const getAllTasks = async () => {
    try {
      let res = await TaskService.getAllPlannerTasks(user.id);
      getProgressChartData(res.data);
      getDelayChartData(res.data);
      await getAllInvites();
    } catch (error) {
      setLoading(false);
      console.log("🚀 ~ getAllTasks ~ error:", error);
    }
  };

  const getAllInvites = async () => {
    try {
      let res = await InviteService.getAcceptedAll(user.project.id);
      // setInvites(res.data);
      calcImpact(res.data);
      setUpdatePanelData({ invites: res.data, ...calcImpact(res.data) });
    } catch (error) {
      console.log("🚀 ~ getAllInivtes ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const delayBarChat = (updatesData) => {
    let updates = [...updatesData];
    //filter updates according to selected stakeholder
    if (selectedStakeholder.code !== "All") {
      updates = updatesData.filter((update) => update.code === selectedStakeholder.code);
    }
    // Extract the min and max dates
    const minMaxDates = updates.reduce(
      (acc, curr) => {
        const currDate = curr.actionDate;
        if (!acc.min || currDate < acc.min) acc.min = currDate;
        if (!acc.max || currDate > acc.max) acc.max = currDate;
        return acc;
      },
      { min: null, max: null }
    );

    //get labels like 04/2024, 05/2024, .... 10/2024
    const labels = [];
    const start = moment(minMaxDates.min);
    const end = moment(minMaxDates.max);
    while (start.isSameOrBefore(end, "month")) {
      labels.push(start.format("MM/YYYY"));
      start.add(1, "month");
    }

    let data = [];
    for (let label of labels) {
      let totalImpact = 0;
      for (let update of updates) {
        if (moment(update.actionDate).isSame(moment(label, "MM/YYYY"), "month")) {
          totalImpact += update.impact;
        }
      }
      data.push(totalImpact);
    }

    setBarData({ labels, data });
  };

  const getProgressChartData = (tasks) => {
    let notStartedCount = 0,
      inProgressCount = 0,
      completedCount = 0;
    for (let task of tasks) {
      if (task.PercentComplete === 0) {
        notStartedCount++;
      } else if (task.PercentComplete < 100) {
        inProgressCount++;
      } else if (task.PercentComplete === 100) {
        completedCount++;
      }
    }
    setProgressChartData({
      notStarted: notStartedCount,
      inProgress: inProgressCount,
      completed: completedCount,
    });
  };

  const getDelayChartData = (tasks) => {
    // for (let task of tasks) {
    //   console.log("🚀 ~ getDelayChartData ~ task:", task);
    // }
  };

  const calcImpact = (invites) => {
    let tImpact = 0;
    let tActivities = 0;
    for (let invite of invites) {
      tImpact += invite.impact;
      tActivities += invite.updateCount;
    }
    return { totalImpact: tImpact, updatedActivityCount: tActivities };
  };

  const getAllStakeholders = async () => {
    try {
      let response = await InviteService.getAcceptedAll(user.project.id);
      let temp = [{ name: "All stakeholders", code: "All", userId: -1 }];
      for (let invite of response.data) {
        temp.push({ code: invite.code, name: invite.invited.stakeholder.name, userId: invite.invited.id });
      }
      setSelectedStakeholder(temp[0]);
      setStakeholderArray(temp);
    } catch (err) {
      console.log("Fetching invites error: ", err);
    }
  };

  return (
    <div className="w-full h-full">
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <PuffLoader
            color="#007DE5"
            loading={loading}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="w-full">
          <div className="w-[200px] my-2">
            <StakeholderDropDown
              stakeholders={stakeholderArray}
              selectedStakeholder={selectedStakeholder}
              onSelect={(value) => setSelectedStakeholder(value)}
            />
          </div>
          <div className="w-full flex gap-[20px] pb-2 pr-2">
            <div className="w-[40%] ">
              <div className="w-full mb-4 pb-2 pr-2">
                <BarChart data={barData} />
              </div>
              <div className="w-full flex-grow overflow-auto pb-2 pr-2">
                {updatePanelData && <UpdatesPanel data={updatePanelData} />}
              </div>
            </div>

            <div className="w-[60%]">
              {/* <div className="w-[50%] flex-shrink-0">
                <PieChart data={progressChartData} />
              </div> */}
              <div className="w-full">
                <div className="w-[40%] min-w-[400px]">
                  <TaskDropDown selectedTask={selectedTask} onSelect={setSelectedTask} tasks={tasks} />
                </div>
                <div className="h-[15px]" />
                <LogTable logs={activityLogs} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
