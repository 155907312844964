import { current } from "@reduxjs/toolkit";
import api from "./api";
import AuthService from "./auth.service";

const create = (data) => {
  return api.post("/api/message/create", data);
};

const MessageService = {
  create,
};

export default MessageService;
