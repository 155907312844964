import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TabBar = ({ tabIndex, tabClicked }) => {
  const navigate = useNavigate();

  const tabs = ["Overview", "Updates", "Packages & Activities"];
  const links = ["/planner/updater/overview", "/planner/updater/updates", "/planner/updater/packages"];

  return (
    <div className="bg-[#E5E7F4] w-full h-[48px]">
      <div className="flex">
        {tabs.map((tab, index) => (
          <div
            key={tab}
            className={`px-4 h-[48px] cursor-pointer flex items-center justify-center ${
              tabIndex === index ? "bg-white text-black" : "text-gray-600"
            }`}
            onClick={() => {
              tabClicked(index);
              navigate(links[index]);
            }}
          >
            <p className="font-OpenSansMedium text-[16px] text-[#606060] ">{tab}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabBar;
