import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import BlueButton from "src/components/buttons/BlueButton";
import ProgressBar from "src/components/ProgressBar";
import TableFooter from "src/components/TableFooter";
import TaskService from "src/services/task.service";
import UserService from "src/services/user.service";
import { useDispatch, useSelector } from "react-redux";
import { setInvites, setTasks } from "src/redux/slices/TasksSlice";
import PuffLoader from "react-spinners/PuffLoader";
import InviteService from "src/services/invite.service";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#007DE5",
};

const Updates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = UserService.getCurrentUser();
  const tasks = useSelector((state) => state.Tasks.tasks);
  const invites = useSelector((state) => state.Tasks.invites);
  const [loading, setLoading] = useState(false);
  const [totalImpact, setTotalImpact] = useState(0);
  const [totalActivities, setTotalActivities] = useState(0);

  useEffect(() => {
    getAllTasks();
    // getAllInvites();
  }, []);

  const getAllInvites = async () => {
    setLoading(true);
    try {
      let res = await InviteService.getAcceptedAll(user.project.id);
      dispatch(setInvites(res.data));
    } catch (error) {
      console.log("🚀 ~ getAllInivtes ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let tImpact = 0;
    let tActivities = 0;
    for (let invite of invites) {
      tImpact += invite.impact;
      tActivities += invite.updateCount;
    }
    setTotalImpact(tImpact);
    setTotalActivities(tActivities);
  }, [invites]);

  const getAllTasks = async () => {
    setLoading(true);
    try {
      let res = await TaskService.getAllTasks(user.id);
      // dispatch(setTasks(res.data));
      await getAllInvites();
    } catch (error) {
      await getAllInvites();
      setLoading(false);
      console.log("🚀 ~ getAllTasks ~ error:", error);
    }
  };

  return (
    <div className="w-full h-full  flex flex-col ">
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <PuffLoader
            color="#007DE5"
            loading={loading}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <div className="w-full mt-5 bg-[#fafafd] shadow-lg p-[16px] flex flex-col ">
            <p className="font-OpenSansRegular text-[16px] font-black my-5">SUMMARY</p>
            <div className="w-full h-[120px] flex gap-[16px]">
              <div className="flex items-center justify-center w-full h-full bg-white border-[#ced0dc] border-[2px] rounded-md shadow-lg">
                <div className="flex flex-col items-center">
                  <span className="font-ChivoMonoMedium text-[48px] text-[#f00]">{totalImpact}</span>
                  <p className="font-OpenSansMedium text-[12px] font-black mt-[-8px]">Total Slack Impact (Days)</p>
                </div>
              </div>

              {/* <div className="flex items-center justify-center w-full h-full bg-white border-[#ced0dc] border-[2px] rounded-md shadow-lg">
                <div className="flex flex-col items-center">
                  <span className="font-ChivoMonoMedium text-[48px] text-[#62a200]">2</span>
                  <p className="font-OpenSansMedium text-[12px] font-black mt-[-8px]">ADVANCED ACTIVITIES</p>
                </div>
              </div> */}

              <div className="flex items-center justify-center w-full h-full bg-white border-[#ced0dc] border-[2px] rounded-md shadow-lg">
                <div className="flex flex-col items-center">
                  <span className="font-ChivoMonoMedium text-[48px] text-[#333333]">{totalActivities}</span>
                  <p className="font-OpenSansMedium text-[12px] font-black mt-[-8px]">ACTIVITIES UPDATED</p>
                </div>
              </div>
            </div>

            <p className="font-OpenSansRegular text-[16px] font-black my-5">UPDATE LIST</p>
            <div className="bg-white shadow-md rounded-[4px] ">
              <div className="w-full"></div>
              <table className="w-full ">
                <thead>
                  <tr className=" border-b-[2px] border-borderColor ">
                    <th className="w-[10px]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center px-2 h-[40px] text-[#606060]">
                        <span>#</span>
                      </div>
                    </th>

                    <th className="w-[20%]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center h-[40px] text-[#606060]">
                        <span>PACKAGE</span>
                      </div>
                    </th>
                    <th className="w-[20%]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center h-[40px] text-[#606060]">
                        <span>STAKEHOLDER</span>
                      </div>
                    </th>

                    <th className="w-[20%]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                        <span>UPDATES</span>
                      </div>
                    </th>

                    <th className="w-[20%]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                        <span>IMPACT</span>
                      </div>
                    </th>

                    {/* <th className="w-[15%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>UPDATES </span>
                  </div>
                </th>

                <th>
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>PROGRESS </span>
                  </div>
                </th> */}

                    <th className="w-[100px]">
                      <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                        <span>ACTIONS </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invites.map((invite, index) => (
                    <tr key={index} className="h-[80px] w-full">
                      <td className="px-2">
                        <span className="font-OpenSansRegular text-[14px] text-[#333333]">{index + 1}</span>
                      </td>
                      <td>
                        <span className="font-OpenSansRegular text-[14px] text-[#333333]">{invite.code}</span>
                      </td>
                      <td>
                        <span className="font-OpenSansRegular text-[14px] text-[#333333]">
                          {invite.invited.stakeholder.name}
                        </span>
                      </td>
                      <td>
                        <div className="w-full flex items-center justify-center">
                          <span className="font-OpenSansRegular text-[14px] text-[#333333]">{invite.updateCount}</span>
                        </div>
                      </td>

                      <td>
                        <div className="w-full flex items-center justify-center">
                          <span className="font-OpenSansRegular text-[14px] text-[#333333]">{invite.impact}</span>
                        </div>
                      </td>

                      {/* <td>
                        <div className="flex items-center justify-center">
                          <span className="font-OpenSansRegular text-[14px] text-[#333333]">
                            {task.packageCode ? task.packageCode : "N/A"}
                          </span>
                        </div>
                      </td> */}
                      {/* <td>
                    <div className="flex items-center w-full justify-center">
                      <div className="w-full flex items-center max-w-[500px] gap-2">
                        <span className="font-OpenSansRegular text-[14px] text-[#333333]">
                          {update.progress.done}/{update.progress.total}
                        </span>
                        <div className="w-full h-[24px]">
                          <ProgressBar progress={20} />
                        </div>
                        <span className="font-OpenSansRegular text-[14px] text-[#333333]">
                          {((update.progress.done / update.progress.total) * 100).toFixed(0)}%
                        </span>
                      </div>
                    </div>
                  </td> */}
                      <td>
                        <div className=" flex items-center justify-center">
                          <div className="w-[100px]">
                            <BlueButton
                              title="Review"
                              onClick={() => navigate(`/planner/updater/updates/${invite.id}`)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <TableFooter pageNumber={1} totalNumber={100} /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Updates;
