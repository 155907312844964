import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const BarChart = ({ data }) => {
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
  });

  useEffect(() => {
    let backgroundColors = [];
    for (let label of data.labels) {
      backgroundColors.push("rgb(0, 125, 229)");
    }
    setBarData({
      labels: data.labels,
      datasets: [{ data: data.data, backgroundColor: backgroundColors }],
    });
  }, [data]);

  return (
    <div className="bg-white shadow-md rounded-sm p-3">
      <div className="flex items-center justify-center">
        <span className="font-OpenSansMedium text-[16px] font-black mt-1 ">DELAY BY CYCLE</span>
      </div>
      <Bar data={barData} options={options} />
    </div>
  );
};

export default BarChart;
