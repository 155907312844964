import api from "./api";
import TokenService from "./token.service";

// const register = (name, email, password, business) => {
//   return api.post("/api/auth/register", {
//     name,
//     email,
//     password,
//     business,
//   });
// };

const login = (email, password) => {
  return api.post(
    "/api/auth/login",
    {
      email,
      password,
    },
    { withCredentials: true }
  );
};

const resetPassword = (email, newPassword) => {
  return api.post(
    "/api/auth/reset-password",
    {
      email,
      newPassword,
    },
    { withCredentials: true }
  );
};

const logout = () => {
  TokenService.removeUser();
  TokenService.removeAccessToken();
};

const getMSAccessToken = (code) => {
  return api.get("/api/auth/get_ms_access_token", {
    params: {
      code,
      project: "updater",
    },
  });
};

const AuthService = {
  // register,
  login,
  logout,
  resetPassword,
  getMSAccessToken,
};

export default AuthService;
