import React, { useEffect, useState } from "react";
import TaskService from "src/services/task.service";
import UserService from "src/services/user.service";
import PackageComponent from "../components/PackageComponent";

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const user = UserService.getCurrentUser();

  useEffect(() => {
    TaskService.getAllPackages(user.project.id)
      .then((res) => {
        setPackages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="w-full h-full bg-white py-[16px]">
      <div className="flex items-center">
        <div className="w-[30%] pl-6">
          <p className="font-OpenSansBold text-[14px] text-[#606060] ">PACKAGE</p>
        </div>
        <div className="w-[50%] flex items-center pl-2">
          {/* <p className="font-OpenSansBold text-[14px] text-[#606060] w-[320px] ">ID</p> */}
          <p className="font-OpenSansBold text-[14px] text-[#606060] ">ACTIVITY</p>
        </div>
        <div className="w-[20%] pl-6">
          <p className="font-OpenSansBold text-[14px] text-[#606060] ">STAKEHOLDER</p>
        </div>
      </div>
      <div className="w-full border-t-[2px] border-gray-300">
        {packages.map((item, index) => (
          <div key={index}>
            <PackageComponent index={index} data={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Packages;
