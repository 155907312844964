import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import BlueButton from "src/components/buttons/BlueButton";
import WhiteButton from "src/components/buttons/WhiteButton";
import TextInput from "src/components/inputs/TextInput";
import UserService from "src/services/user.service";
import { getImageUrl } from "src/utils/common";

const Account = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");

  const submit = () => {
    const user = UserService.getCurrentUser();
    let data;
    if (user.role.name === "Planner") {
      data = { id: user.id, planner: { name, company } };
    } else if (user.role.name === "Stakeholder") {
      data = { id: user.id, stakeholder: { name, company } };
    }

    UserService.updateUser(data)
      .then(function (response) {
        if (response.status == 201) {
          if (user.role.name === "Planner") {
            navigate("/auth/connect-msproject");
          } else if (user.role.name === "Stakeholder") {
            navigate("/auth/welcome");
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
  };

  // const cancel = () => {
  //   navigate("/auth/welcome");
  // };

  return (
    <div className="w-screen h-screen flex items-center justify-center relative ">
      <img src={getImageUrl("/images/auth/bg.svg")} className="w-screen h-screen absolute left-0 top-0 object-cover" />

      <div className="bg-[#E5E7F488] w-[332px] rounded-md z-10 px-[16px] py-[32px] flex items-center flex-col">
        <img src={getImageUrl("/images/common/logo.png")} className="w-[34px] h-[34px]" />
        <span className="text-[#333333] text-[20px] font-OpenSansBold mt-[8px] ">Let's create your account</span>
        <p className="w-full text-[#333333] text-[12px] font-OpenSansBold mt-[40px]">FULL NAME</p>
        <TextInput onTextChanged={(value) => setName(value)} />

        <p className="w-full text-[#333333] text-[12px] font-OpenSansBold mt-[16px] ">COMPANY</p>
        <TextInput onTextChanged={(value) => setCompany(value)} />

        {/* <p className="w-full text-[#333333] text-[12px] font-OpenSansBold mt-[16px] ">PROFILE IMAGE</p>
        <div className="flex items-center w-full mt-[16px]">
          <img src={getImageUrl("/images/auth/eclipse.svg")} className="w-[120px] h-[120px]" />
          <div className="w-[100px]">
            <WhiteButton title="UPLOAD" fontSize={12} />
          </div>
        </div> */}

        <div className="w-full mt-[40px] flex gap-2">
          {/* <WhiteButton title="SKIP" onClick={cancel} /> */}
          <BlueButton title="NEXT" onClick={submit} />
        </div>
      </div>
    </div>
  );
};

export default Account;
