import React from "react";

import { formatDate } from "src/utils/common";

// const logData = [
//   {
//     date: "12/10/2024",
//     stakeholder: "Colin Harman",
//     activity: "2nd floor framing",
//     update: "START DATE 2/5/2025 → 2/29/2025",
//   },
//   {
//     date: "9/11/2024",
//     stakeholder: "Alex",
//     activity: "Landscaping",
//     update: "END DATE 2/5/2025 → 2/29/2025,  CATEGORY: PAYMENT, EXPLANATION: Check didn't arrive.",
//   },
//   {
//     date: "9/4/2024",
//     stakeholder: "Alex Steel",
//     activity: "Foundation inspection",
//     update: "COMPLETION % 25 → 50",
//   },
//   {
//     date: "8/29/2024",
//     stakeholder: "Yigit",
//     activity: "Generator installation",
//     update: "START DATE 2/5/2025 → 2/29/2025",
//   },
// ];

const LogTable = ({ logs }) => {
  return (
    <div className="w-full flex-auto bg-white rounded-md shadow-md max-h-[600px] overflow-y-auto">
      <table className="w-full rounded-md">
        <thead>
          <tr className=" border-b-[2px] border-borderColor ">
            <th className="w-[20%]">
              <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                <span>DATE</span>
              </div>
            </th>
            <th className="w-[20%]">
              <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                <span>PARTY</span>
              </div>
            </th>
            <th className="w-[60%]">
              <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                <span>ACTIVITY</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <tr key={index} className="w-full h-[30px]">
              <td>
                <div className="w-full flex items-center justify-center">
                  <span className="font-OpenSansRegular text-[12px] text-[#333333]">{formatDate(log.date)}</span>
                </div>
              </td>
              <td>
                <div className="w-full flex items-center justify-center">
                  <span className="font-OpenSansRegular text-[12px] text-[#333333]">{log.party}</span>
                </div>
              </td>
              <td>
                <div className="w-full flex items-center justify-center px-1">
                  <span className="font-OpenSansRegular text-[12px] text-[#333333]">{log.activity}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LogTable;
