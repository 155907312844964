import React from "react";

const PackageComponent = ({ index, data }) => {
  const colorClass = `PACKAGE_COLOR_${(index % 6) + 1}`;
  const activityBgColor1 = "#F5F5FB",
    activityBgColor2 = "#E5E7F4";

  return (
    <div className="w-full flex border-b-[2px] border-gray-300 bg-white">
      <div className="w-[30%] flex">
        <div className={`w-[16px] h-full ${colorClass}`} />
        <div className="h-full flex-auto flex items-center">
          <p className="text-[16px]  font-OpenSansMedium text-[#333333] ml-2">{data.task_code}</p>
        </div>
      </div>
      <div className="w-[50%]">
        {data.tasks.map((activity, index) => (
          <div
            key={index}
            className="w-full  flex pl-2"
            style={{ backgroundColor: index % 2 === 0 ? activityBgColor1 : activityBgColor2 }}
          >
            {/* <p className="font-OpenSansRegular text-[14px] text-[#606060] min-w-[270px]">{activity.taskId}</p> */}
            <p className="font-OpenSansRegular text-[14px] text-[#606060] flex-auto ml-2">{activity.name}</p>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center flex-auto">
        <p className="text-[14px] font-OpenSansRegular text-[#333333] ml-2">{data.stakeholder}</p>
      </div>
    </div>
  );
};

export default PackageComponent;
