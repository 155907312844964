import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";
import SideBar from "./SideBar";

const MainLayout = () => {
  return (
    <div className="flex flex-col w-screen h-screen bg-[#F5F5FB] ">
      <TopBar />
      <div className="flex-auto flex">
        <SideBar />
        <div className="flex-auto h-screen-minus-48 px-[24px] py-[16px] overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
