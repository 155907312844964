import React, { useEffect } from "react";

const UpdatesPanel = ({ data }) => {
  return (
    data && (
      <div className="w-full h-full flex flex-col gap-5">
        <div className="w-full h-[54px] bg-borderColor flex items-center justify-between gap-4">
          <div className="w-full h-full bg-[#E5E7F4] flex items-center justify-center rounded-md shadow-md">
            <div className="flex items-center ">
              <span className="font-OpenSansBold text-[31px] font-black ">{data.updatedActivityCount}&nbsp;</span>
              <span className="font-OpenSansRegular 2xl:text-[16px] text-[12px] font-black mt-1">
                ACTIVITIES UPDATED
              </span>
            </div>
          </div>
          <div className="w-full h-full bg-[#FEE2E2] flex items-center justify-center rounded-md shadow-md">
            <div className="flex items-center ">
              <span className="font-OpenSansBold text-[31px] text-[#EF4444] ">{data.totalImpact}&nbsp;</span>
              <span className="font-OpenSansRegular 2xl:text-[16px] text-[11px] font-black mt-1">
                TOTAL IMPACT (DAYS)
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex-auto bg-white rounded-md shadow-md">
          <table className="w-full rounded-md">
            <thead>
              <tr className=" border-b-[2px] border-borderColor ">
                <th className="w-[30%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>PACKAGE</span>
                  </div>
                </th>
                <th className="w-[30%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>STAKEHOLDER</span>
                  </div>
                </th>
                <th className="w-[40%]">
                  <div className="font-OpenSansRegular text-[12px] flex items-center justify-center h-[40px] text-[#606060]">
                    <span>DELAYED FINISH DATES</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.invites &&
                data.invites.map((invite, index) => (
                  <tr key={index} className="h-[80px] w-full">
                    <td>
                      <div className="w-full flex items-center justify-center">
                        <span className="font-OpenSansRegular text-[14px] text-[#333333]">{invite.code}</span>
                      </div>
                    </td>
                    <td>
                      <div className="w-full flex items-center justify-center">
                        <span className="font-OpenSansRegular text-[14px] text-[#333333]">
                          {invite.invited.stakeholder.name}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="w-full flex items-center justify-center">
                        <span className="font-OpenSansRegular text-[14px] text-[#333333]">
                          {invite.delayedEndDateUpdateCount > 0 && invite.delayedEndDateUpdateCount}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};

export default UpdatesPanel;
