import { current } from "@reduxjs/toolkit";
import api from "./api";
import AuthService from "./auth.service";

const getAll = () => {
  return api.get("/api/delaycategory/get-all");
};

const Delaycategory = {
  getAll,
};

export default Delaycategory;
