import { current } from "@reduxjs/toolkit";
import api from "./api";
import AuthService from "./auth.service";

const getAll = (userId) => {
  return api.get("/api/code/get-all", { params: { userId } });
};

const CodeService = {
  getAll,
};

export default CodeService;
